import "core-js/modules/es6.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import RedeemGiftCarrier from '@modules/cash/components/redeem/redeem-gift/RedeemGiftCarrier';
import RedeemGiftProducts from '@modules/cash/components/redeem/redeem-gift/RedeemGiftProducts';
import RedeemGiftForm from '@modules/cash/components/redeem/redeem-gift/RedeemGiftForm';
import NumberFormat from '@modules/cash/mixins/NumberFormat';
export default {
  name: 'RedeemWithGift',
  mixins: [NumberFormat],
  components: {
    RedeemGiftCarrier: RedeemGiftCarrier,
    RedeemGiftProducts: RedeemGiftProducts,
    RedeemGiftForm: RedeemGiftForm
  },
  props: {
    stepBackForGift: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isShowProducts: false,
      isShowProductForm: false,
      isLoading: false
    };
  },
  computed: {
    paynupCarrier: function paynupCarrier() {
      return this.$store.getters['redeem/paynupCarrier'];
    },
    winnings: function winnings() {
      return window.$store.getters['balance/playerBalance'].winnings;
    },
    filteredPaynupCarrier: function filteredPaynupCarrier() {
      return this.paynupCarrier.filter(function (carrier) {
        return carrier.edges.length;
      });
    }
  },
  watch: {
    stepBackForGift: function stepBackForGift(value) {
      if (value) {
        var isGoToMethods = !this.isShowProductForm && !this.isShowProducts || this.isShowProducts && this.filteredPaynupCarrier.length === 1;
        if (isGoToMethods) this.$emit('isShowGift', false);
        if (this.isShowProductForm) this.isShowProductForm = false;else this.isShowProducts = false;
        this.$emit('makeStepBackForGift');
      }
    }
  }
};