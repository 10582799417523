//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'SumsubInfo',
  computed: {
    appMain: function appMain() {
      return this.$store.getters['cash/profile'];
    },
    isPhoneConfirmed: function isPhoneConfirmed() {
      return this.appMain.confirmed;
    }
  },
  watch: {
    isPhoneConfirmed: function isPhoneConfirmed(value) {
      if (value) this.showSumsub();
    }
  },
  methods: {
    handleClickBtn: function handleClickBtn() {
      if (!this.isPhoneConfirmed) this.openVerificationPhone();else this.showSumsub();
    },
    showSumsub: function showSumsub() {
      this.$emit('showSumsub');
    },
    openVerificationPhone: function openVerificationPhone() {
      window.dispatchEvent(new CustomEvent('SHOW_VERIFICATION_PHONE', {
        detail: {
          location: 'redeem'
        }
      }));
    }
  }
};