import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'RedeemGiftCarrier',
  computed: {
    paynupCarrier: function paynupCarrier() {
      return this.$store.getters['redeem/paynupCarrier'].filter(function (carrier) {
        return carrier.edges.length;
      });
    }
  },
  methods: {
    setPaynupProducts: function setPaynupProducts(carrier) {
      this.$store.dispatch('redeem/setPaynupActiveCarrierName', carrier.name || '');
      this.$store.dispatch('redeem/setPaynupProducts', carrier);
      this.$emit('isShowProducts');
    }
  },
  created: function created() {
    if (this.paynupCarrier.length === 1) {
      this.setPaynupProducts(this.paynupCarrier[0]);
    }
  }
};