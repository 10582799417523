//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import uploadImages from '@modules/mixins/upload-images';
import api from '@modules/cash/services/api';
export default {
  name: 'DocumentsVerification',
  mixins: [uploadImages],
  props: {
    comment: {
      type: String,
      default: ''
    }
  },
  methods: {
    submitDocuments: function submitDocuments() {
      this.sendFiles();
    },
    handleFileUpload: function handleFileUpload() {
      this.addLocalFile(this.$refs.file.files[0]);
      this.$refs.file.value = null;
    },
    onSuccessUploadDocs: function onSuccessUploadDocs() {
      this.$emit('onSuccessUploadDocs');
    }
  },
  created: function created() {
    this.settings.endpointMethod = api.player.sendDocuments;
    this.settings.allProcessedCallback = this.onSuccessUploadDocs;
  }
};