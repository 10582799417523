//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'DepositSuccess',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    phone: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      isLoading: false
    };
  },
  methods: {
    close: function close() {
      this.$emit('exitConfirm');
    },
    confirm: function confirm() {
      this.isLoading = true;
      this.$emit('confirm');
    }
  }
};